import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import logoLast9 from "../images/logo-last9.svg";
import logoMarkk from "../images/logo-markk.svg";
import logoQuinto from "../images/logo-quintoai.svg";
import logoTTS from "../images/logo-the-tossed-salad.svg";
import logoYolkshire from "../images/logo-yolkshire.svg";
import illustration from "../images/illustration.png";

const AboutPage = () => {
  return (
    <Layout>
      {/* header start */}
      <div className="overflow-hidden bg-yellow-400 px-6 pt-6 pb-28 sm:px-0">
        <div className="relative mx-auto max-w-lg">
          {/* nav start */}
          <span className="relative z-10 flex gap-4">
            <Link
              to="/"
              className="text-base font-semibold text-black/50 hover:text-black hover:underline">
              Sahil Khan
            </Link>
            <Link to="/about" className="text-base font-semibold text-black">
              About
            </Link>
            <Link
              to="/blog"
              className="text-base font-semibold text-black/50 hover:text-black hover:underline">
              Blog
            </Link>
          </span>
          {/* nav end */}
          <p className="mt-28 w-48 text-3xl font-bold text-black">Hi there!</p>
          <img
            src={illustration}
            alt="illustration of Sahil Khan"
            className="absolute top-0 -right-36 w-96 sm:-top-6 sm:-right-52"
          />
        </div>
      </div>
      {/* header end */}

      {/* body start */}
      <div className="mx-auto max-w-lg overflow-hidden px-6 pt-12 sm:overflow-visible sm:px-0">
        <main className="prose prose-invert prose-a:text-gray-400 hover:prose-a:text-gray-200 prose-hr:border-gray-800">
          <p className="text-2xl font-bold text-white">
            I used to run a startup, a restaurant, a web magazine, and
            freelanced as a designer. Now I draw rectangles for startups.
          </p>

          <p className="mb-10">
            <a href="#footer">Reach out to me</a> if you want to chat, think I
            can help you with something, or just want to grab a coffee or beer.
          </p>

          <div className="not-prose mb-10 grid grid-cols-2 justify-start gap-3 md:grid-cols-3 lg:flex lg:flex-row lg:flex-nowrap lg:justify-center">
            {/* start card 1 */}
            <div className="flex aspect-[3/4] w-full min-w-[33.33%] flex-col justify-between rounded-2xl bg-neutral-900 p-3 lg:w-[33.33%]">
              <img className="w-12" src={logoLast9} alt="Last9" />
              <div className="flex flex-col">
                <a
                  href="https://last9.io"
                  className="mb-0.5 font-bold leading-5 text-gray-200">
                  Last9 ↗
                </a>
                <span className="mb-0.5 text-sm text-gray-400">
                  Sr Designer
                </span>
                <span className="text-sm text-yellow-400">2021&mdash;Now</span>
              </div>
            </div>
            {/* end card 1 */}

            {/* start card 2 */}
            <div className="flex aspect-[3/4] w-full min-w-[33.33%] flex-col justify-between rounded-2xl bg-neutral-900 p-3 lg:w-[33.33%]">
              <img className="w-12" src={logoMarkk} alt="Markk" />
              <div className="flex flex-col">
                <span className="mb-0.5 font-bold leading-5 text-gray-200">
                  Markk
                </span>
                <span className="mb-0.5 text-sm text-gray-400">
                  Sr UX Engineer
                </span>
                <span className="text-sm text-yellow-400">2018&mdash;2021</span>
              </div>
            </div>
            {/* end card 2 */}

            {/* start card 3 */}
            <div className="flex aspect-[3/4] w-full min-w-[33.33%] flex-col justify-between rounded-2xl bg-neutral-900 p-3 lg:w-[33.33%]">
              <img className="w-12" src={logoQuinto} alt="Quinto" />
              <div className="flex flex-col">
                <span className="mb-0.5 w-fit rounded-full bg-neutral-800 py-1 px-2 text-[10px] font-bold uppercase leading-[16px]">
                  Acquired
                </span>
                <span className="mb-0.5 font-bold leading-5 text-gray-200">
                  Quinto
                </span>
                <span className="mb-0.5 text-sm text-gray-400">Cofounder</span>
                <span className="text-sm text-yellow-400">2013&mdash;2018</span>
              </div>
            </div>
            {/* end card 3 */}

            {/* start card 4 */}
            <div className="flex aspect-[3/4] w-full min-w-[33.33%] flex-col justify-between rounded-2xl bg-neutral-900 p-3 lg:w-[33.33%]">
              <img className="w-12" src={logoTTS} alt="The Tossed Salad" />
              <div className="flex flex-col">
                <span className="mb-0.5 font-bold leading-5 text-gray-200">
                  The Tossed Salad
                </span>
                <span className="mb-0.5 text-sm text-gray-400">Founder</span>
                <span className="text-sm text-yellow-400">2008&mdash;2013</span>
              </div>
            </div>
            {/* end card 4 */}

            {/* start card 5 */}
            <div className="flex aspect-[3/4] w-full min-w-[33.33%] flex-col justify-between rounded-2xl bg-neutral-900 p-3 lg:w-[33.33%]">
              <img className="w-12" src={logoYolkshire} alt="Yolkshire" />
              <div className="flex flex-col">
                <span className="mb-0.5 font-bold leading-5 text-gray-200">
                  Yolkshire
                </span>
                <span className="mb-0.5 text-sm text-gray-400">Partner</span>
                <span className="text-sm text-yellow-400">2010&mdash;2011</span>
              </div>
            </div>
            {/* end card 5 */}
          </div>

          <hr />

          <p>
            <span className="mr-1">📚</span> I'm a graduate from Symbiosis
            Institute of Computer Studies & Research, Pune. While I barely
            learnt anything in class, I do appreciate the support from the staff
            to help discover myself and get started on the path that I am on
            today.
          </p>
          <p>
            <span className="mr-1">🏠</span> I moved to Bangalore mid-2021, had
            been based in Pune for 15 years before that, and had previously
            lived in Lucknow (I was in the hostel, so does it really count?) and
            Saudi Arabia.
          </p>
          <p>
            <span className="mr-1">🚴‍♂️</span> I enjoy{" "}
            <a href="https://www.strava.com/athletes/sahilkhan">cycling</a> (not
            much these days but quite happy with the 112kms in 6 hours from Pune
            to Lonavala and back), <span className="mr-1">🍜</span> cooking (did
            a{" "}
            <a href="https://twitter.com/peeyuka/status/939714074048323584?lang=el">
              small
            </a>{" "}
            <a href="https://www.livemint.com/news/business-of-life/a-new-recipe-to-spice-up-the-old-book-clubs-1548083669803.html">
              cookbook club
            </a>{" "}
            in Pune to push ourselves out of our comfort zone to cook across
            cuisines and techniques, and{" "}
            <Link to="/blog/bcbcbath-bangalore-cookbook-club/">
              now in Bangalore as well
            </Link>
            ), <span className="mr-1">☕</span>
            <a href="https://lifestyle.livemint.com/news/big-story/coffee-comes-of-age-in-the-home-kitchen-111604326095064.html">
              playing around with coffee
            </a>
            . And of course, <a href="http://instagram.com/sahilk88">eating</a>.
            Duh.
          </p>
        </main>
      </div>
      {/* body end */}
    </Layout>
  );
};

export default AboutPage;

export const Head = () => <Seo title="About" />;
